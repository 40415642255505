:root {
  --header-color: #ffffff;
  --sidebar-color: #2f4f4f;
  --header-font: 'Arial', sans-serif;
  --sidebar-font: 'Verdana', sans-serif;
}

.header {
  background-color: var(--header-color);
  font-family: var(--header-font);
}

.sidebar {
  background-color: var(--sidebar-color);
  font-family: var(--sidebar-font);
}

body{
  font-family: 'Poppins', sans-serif;
  font-size: 0.85rem;
 }


 
 label{
  font-weight: 600;
 }

 .form-check-input{
  border: 1px solid rgb(56, 56, 207) !important;
 }

 .btn-addbutton,  .addbutton{
  background-color: var(--sidebar-color);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
